import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Container, DateSelector, Label } from './style';

export const DateContext = React.createContext({});

const DateInput = ({ label,  startInput = true }) => {
  const [dateStart, setDateStart] = useState(moment().subtract(1, 'days').startOf('day').format("YYYY-MM-DDTHH:mm"));
  const [dateEnd, setDateEnd] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  
  const {setDateStartP, setDateEndP} = React.useContext(DateContext);
  
  const handleSetDate = useCallback((date) => {
    
    if (startInput) {
      setDateStart(date) 
      
      setDateStartP(date)
    } else {
       setDateEnd(date)
       setDateEndP(date)
      };
     

  }, [startInput, setDateStartP, setDateEndP])
  

  return (
    <DateContext.Provider value={{dateStart, dateEnd}}>
    <Container>
    <Label>{label}</Label>
    <DateSelector  
        type="datetime-local"
        value={startInput ? dateStart : dateEnd}
        min="2021-01-11T00:00:00"
        max="2040-01-01T00:01:00"
        onChange={e => handleSetDate(e.target.value)}
    />
    </Container>
    </DateContext.Provider>
  )
}


export const DateProvider = (props) => {
  const [dateStartP, setDateStartP] = useState(moment().subtract(1, 'days').startOf('day').format("YYYY-MM-DDTHH:mm"));
  const [dateEndP, setDateEndP] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  
  return (
    <DateContext.Provider value={{dateStartP, setDateStartP, dateEndP, setDateEndP}}>
      {props.children}
    </DateContext.Provider>
  )
}

export default DateInput;