import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: #ededed;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TableHeader = styled.header`
  width: 100%;
  padding: .9375rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const DateFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 35%;

  svg {
    font-size: 32px;
    cursor: pointer;
  }
`;

export const ContainerInput = styled.div`
  display: grid;
  justify-items: stretch;
  align-content: center;
`

export const ContainerDate = styled.div`
  gap: 1.4rem;
`;

export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1.4rem;
`;

export const Title = styled.label``;

export const DocumentFilter = styled.input`
  width: 9.6875rem;
  border-radius: 5px;
  border: solid 1px rgba(128, 128, 128, 0.389);
  text-align: left;
  padding: 4px 10px;
`;

export const TableLog = styled.table`
  width: 90%;
  box-sizing: border-box;
  border-collapse: collapse;
  box-shadow: 0px 0px 5px 1px gray;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  height: 70vh;

  td, th {
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }    
`;

export const TableHead = styled.thead`
  background-color: #c71b1b;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

export const TableHeadData = styled.th`
  color: #fff;
  font-size: 1rem;
`;

export const TableRow = styled.tr`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 20px;

  tbody > & {
    height: 4.5rem;
    width: 100%;
    display: table;
    border-bottom: solid 0.5px rgba(131, 131, 131, 0.175);
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
            
    &:hover {
        background-color: rgb(195, 195, 195);
  }}
`;

export const TableBody = styled.tbody`
  width: 100%;
  height: -webkit-fill-available;
  box-sizing: border-box;
  border-collapse: collapse;
  margin: 0px auto;
  overflow-y: scroll;
  position: absolute;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track:hover,
  &::-webkit-scrollbar-track:active {
    border-radius: 0;
    background-color: #FFFFFF;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb:hover,
  &::-webkit-scrollbar-thumb:active {
    border-radius: 0;
    background-color: #C71B1B;
  }
`;


export const TableData = styled.td`
  border: none;
  font-weight: 300;
  text-align: center;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;

  &.document{
    word-break: break-all;
  }

  svg {
    font-size: 18px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 0.6rem;
  }
  @media only screen and (min-width: 992px) {
    font-size: 0.8rem;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 0.9rem;
  }
`;

export const Button = styled.button``;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
`;